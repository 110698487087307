


import request from '@/utils/request';


class DealBasicApi {

    create_new_deal = (data) => {
        return request({
            url: '/adm/deal/basic/setup_new_deal',
            method: 'post',
            data
        });	
        
    }

    detail = (data) => {

        return request({
            url: '/adm/deal/basic/deal_detail',
            method: 'post',
            data
        });	
    }


    get_new_deal_no = () => {
        return request({
            url: '/adm/deal/basic/get_new_deal_no',
            method: 'post',
           
        });	
        
    }

    get_deal_type_list = () => {
        return request({
            url: '/adm/deal/basic/get_deal_type',
            method: 'post',
           
        });	
        
    }

    query_agent_commission = (data) => {
        return request({
            url: '/adm/deal/basic/query_deal_agent_commission',
            method: 'post',
            data
        });	
    }


    modify_deal = (data) => {
        return request({
            url: '/adm/deal/basic/modify_deal',
            method: 'post',
            data
        });	
    }

    deal_unwind = (data) => {
        return request({
            url: '/adm/deal/basic/delete_deal',
            method: 'post',
            data
        });	
        
    }

    deal_logs = (data) => {
        return request({
            url: '/adm/deal/basic/deal_logs',
            method: 'post',
            data
        });	
        
    }
}


let _api_basic = null

const getDealBasicApi = () => {
    if (!_api_basic) {
        _api_basic = new DealBasicApi();
    }
    return _api_basic;
}

export { getDealBasicApi };