<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import Choices from "choices.js";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import AgentAutoComplete from '@/components/agent-auto-complete-input'
import AddressAutoComplete from '@/components/address-auto-complete-input'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import CurrencyInput from '@/components/currency-input'

import {
  required, email
} from "vuelidate/lib/validators";

import { search_lawyer, postal_code_query } from '@/api/misc'
import { getDealBasicApi } from '@/api/deal/basic'
import { getDealApi } from '@/api/deal'
import { financial } from '@/api/misc'

//import CurrencyInput from '@/components/currency-input'


/**
 * Setup PM  component
 */
export default {
  page: {
    title: "Deal",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  validations() {

    if (this.deal.deal_business != 'P' && this.deal.deal_business != 'H' && this.deal.deal_business != 'T') {

      return {

        deal: {

          selling_price: { required },
          acceptance_date: { required },
          contract_date: { required },
          deposit_due_date: { required },
          deposit: { required },
          listing_commission: { required },
          selling_commission: { required },
          listing_commission_gst: { required },
          selling_commission_gst: { required },
          properties: {
            $each: { address: { required } }
          },

          buyers_sellers: {
            $each: {
              first_name: { required },
              last_name: { required },
              email: { email }
            }
          },
          listing_brokers: {
            $each: {
              agent_name: { required },
              brokerage: { required },
              commission: { required },
              gst: { required }

            }
          },

          selling_brokers: {
            $each: {
              agent_name: { required },
              brokerage: { required },
              commission: { required },
              gst: { required }

            }
          },
        },

        local_agent_sub_commissions: {
          $each: {
            commission: { required },
            gst: { required }
          }
        }
      }

    } else {
      return {

        deal: {

          selling_price: {},
          acceptance_date: {},
          contract_date: {},
          deposit_due_date: {},
          deposit: {},
          listing_commission: {},
          selling_commission: {},
          listing_commission_gst: {},
          selling_commission_gst: {},
          properties: {
            $each: { address: {} }
          },

          buyers_sellers: {
            $each: {
              first_name: {},
              last_name: {},
              email: {}
            }
          },
          listing_brokers: {
            $each: {
              agent_name: {},
              brokerage: {},
              commission: {},
              gst: {}

            }
          },

          selling_brokers: {
            $each: {
              agent_name: {},
              brokerage: {},
              commission: {},
              gst: {}

            }
          },
        },
      }
    }
  },


  methods: {



    formSubmit() {
      console.log(this.deal)

      this.$v.$touch();

      if (this.$v.$invalid == false) {
        let other_brokers = []
      

        this.deal.agents = []
        let brokers = [...this.deal.listing_brokers, ...this.deal.selling_brokers]

        this.deal.listing_brokers.map(e => {
          if (e.agent_in_house_type == 'In House') {
            this.deal.agents.push(e)
          }
        })

        this.deal.selling_brokers.map(e => {
          if (e.agent_in_house_type == 'In House') {
            this.deal.agents.push(e)
          }
        })


        other_brokers.push(...this.deal.listing_brokers, ...this.deal.selling_brokers)

        other_brokers.map(e => {
          if (e.type == 'Selling Broker' || e.type == 'Listing Broker') {
            if (!e.first_name || e.first_name == '' || !e.last_name || e.last_name == '') {
              if (e.agent_name) {
                let arr = e.agent_name.split(" ")
                e.first_name = arr[0]
                e.last_name = arr.length > 1 ? arr[1] : arr[0]
              }
            }

            if (!e.name || e.name == '') {
              e.name = e.brokerage
            }
          }
        })
        let data = {
          deal_id: this.deal.order_deal_no,
          basic: {
            order_deal_id: this.deal.order_deal_no,
            price: this.deal.selling_price,
            contract_date: this.deal.contract_date,
            acceptance_date: this.deal.acceptance_date,
            completion_date: this.deal.completion_date,
            adjustment_date: this.deal.adjustment_date,
            possession_date: this.deal.possession_date,
            subject_remove_date: this.deal.subject_remove_date,
            is_subject_deal: this.deal.no_subject ? 0 : 1,
            recission_date: this.deal.recission_date,
            deal_type: this.deal.deal_type,
            listing_commission: this.deal.listing_commission,
            selling_commission: this.deal.selling_commission,
            listing_commission_gst: this.deal.listing_commission_gst,
            selling_commission_gst: this.deal.selling_commission_gst,
            mls: this.deal.mls,
            deal_business: this.deal.deal_business
          },

          deposits: [{ deposit: this.deal.deposit, due_date: this.deal.deposit_due_date, trust: this.deal.deposit_trust_by }],
          properties: this.deal.properties,
          agents: this.deal.agents,
          buyer_seller_list: this.deal.buyers_sellers,
          lawyer_list: this.deal.lawyers,
          broker_list: brokers,
          deleted_agents : this.deleted_agent
        }
        getDealBasicApi().modify_deal(data).then(res => {
          if (res.errCode == 0) {
            this.$alertify.message("Deal updated");
            this.$router.push({ name: 'deal-pending-list' })
          } else {
            this.$alertify.error("Update Deal failed:" + res.errCode);
          }
        })
      }

    },


    ///////search lawyer

    queryLawyer(str) {
      search_lawyer({ str: str }).then(res => {
        if (res.errCode == 0) {
          this.lawyer_data = []
          res.data.map(e => this.lawyer_data.push(e))

        }
      })
    },

    onLawyerSelected(evt, lawyer, idx) {

      lawyer.name = evt.name
      lawyer.phone = evt.phone1
      lawyer.email = evt.email
      lawyer.address = evt.addr + ' ' + evt.city_name + ' ' + evt.postal
      lawyer.postal_code = evt.postal
      this.$refs['lawyer_address_' + idx][0].setValue(lawyer.address)
      this.$refs['lawyer_name_' + idx][0].inputValue = evt.name

    },

    onLawyerAddreessSelected(addr, evt) {
      let idx = evt.$attrs['lawyer_index']
      let lawyer = this.deal.lawyers[idx]
      lawyer.address = addr.full
    },


    //////////////Buyer/Seller

    onAddNewBuyerSeller() {
      this.deal.buyers_sellers.push({
        type: 'Buyer'
      })

      this.$nextTick(() => {
        let els = document.getElementsByClassName('buyer_seller_choice')
        new Choices(els[els.length - 1], {
          removeItemButton: false,
          itemSelectText: '',
        });
      });
    },

    onBuyerSellerAddressSelected(addr, evt_cmp) {

      let item = this.deal.buyers_sellers[evt_cmp.$attrs['idx']]
      item.address = addr.full
      item.city = addr.city
    },

    onRemoveBuyerSeller(idx) {
      this.deal.buyers_sellers.splice(idx, 1)
    },


    //////////////Property
    onAddressSelected(addr, evt_cmp) {
      let item = this.deal.properties[evt_cmp.$attrs['idx']]
      item.address = addr.full
      item.city_name = addr.city
      item.province_name = addr.province
      item.street = addr.street

      if (addr.reference) {
        postal_code_query({ str: addr.reference }).then(res => {
          if (res.errCode == 0) {
            item.postal_code = res.data
            item.address += item.postal_code
          }
        })
      }
    },


    onAddProperty() {
      let obj = {
        unit: '',
        pid: '',
        address: '',
        city_name: '',
        province_name: '',
        postal_code: '',
        street: ''
      }

      this.deal.properties.push(obj)
    },

    onRemoveProperty(idx) {
      this.deal.properties.splice(idx, 1)
    },




    //////////////Agent
    onSelectedBrokerAgent(selected_agent) {

     

      let idx = selected_agent.user_obj.broker_index
      let btype = selected_agent.user_obj.agent_type

      let broker = null

      if (btype == 'SELLING_BROKER') {
        broker = this.deal.selling_brokers[idx]
      } else {
        broker = this.deal.listing_brokers[idx]
      }

      //local agent searched result
      if (selected_agent.agent.brokerage) {
        broker.agent_id     = selected_agent.agent.id
        broker.brokerage    = selected_agent.agent.brokerage.name
        broker.address      = selected_agent.agent.brokerage.address
        broker.agent_name   = selected_agent.agent.first_name+' '+selected_agent.agent.last_name
        broker.agent_vin    = selected_agent.agent.code
        if (broker.id) {
          broker.is_edit      = true
          broker.agent_update = true
          broker.new_agent_id = selected_agent.agent.id
        }

      } else {
        broker.brokerage    = selected_agent.agent.brokerage_name
        broker.address      = selected_agent.agent.address
        broker.agent_name   = selected_agent.agent_name
        broker.agent_vin    = selected_agent.agent.vin
      }




      broker.name        = broker.brokerage

      broker.first_name  = selected_agent.agent.first_name
      broker.last_name   = selected_agent.agent.last_name
      broker.phone       = selected_agent.agent.phone
      broker.email       = selected_agent.agent.email
      broker.office_code = selected_agent.agent.office_code

    },



    onAddNewLawyer() {

      this.deal.lawyers.push({ type: 'Buyer Lawyer' })

      this.$nextTick(() => {
        let els = document.getElementsByClassName('lawyer_choice')
        new Choices(els[els.length - 1], {
          removeItemButton: false,
          itemSelectText: '',
        });
      })


    },

    onRemoveLawyer(idx) {
      this.deal.lawyers.splice(idx, 1)
    },


    onAddListingBroker() {
      this.deal.listing_brokers.push({ agent_name_required: false, agent_name: '', agent_vin: '', brokerage: '', email: '', phone: '', name: '', commission: 0, gst: 0, total: 0, type: 'Listing Broker', temp_obj_id: ++this.local_obj_id , agent_type : "1", is_new : true})
    },

    onAddSellingBroker() {
      this.deal.selling_brokers.push({ agent_name_required: false, agent_name: '', agent_vin: '', brokerage: '', email: '', phone: '', name: '', commission: 0, gst: 0, total: 0, type: 'Selling Broker', temp_obj_id: ++this.local_obj_id , agent_type : "2", is_new : true})
    },

    onRemoveListingBroker(idx, broker) {
      this.deal.listing_brokers.splice(idx, 1)
      if (broker.agent_in_house_type == 'In House' && broker.id) {
        this.deleted_agent.push(broker)
      }
      this.update_total_commission()
    },

    onRemoveSellingBroker(idx, broker) {

      this.deal.selling_brokers.splice(idx, 1)
      if (broker.agent_in_house_type == 'In House' && broker.id) {
        this.deleted_agent.push(broker)
      }
      this.update_total_commission()
    },



    onAddSubTrade() {
      let sub_deal_arr = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I']
      let idx = 1


      this.local_agent_sub_commissions.push({ commission: 0, gst: 0, agent_name: '', sub_deal_id: '' })
      this.local_agent_sub_commissions.map(e => e.sub_deal_id = this.deal.order_deal_no + '-' + sub_deal_arr[idx++])
    },

    onRemoveSubTrade(ridx) {
      this.local_agent_sub_commissions.splice(ridx, 1)
      let sub_deal_arr = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I']
      let idx = 1
      this.local_agent_sub_commissions.map(e => e.sub_deal_id = this.deal.order_deal_no + '-' + sub_deal_arr[idx++])


      this.update_total_commission()
    },



    comm_watcher(evt, idx, broker) {

      console.log(evt, idx, broker)

      broker.gst     = financial(Number(broker.commission) * 0.05, 2)
      broker.total   = financial(Number(broker.commission) + Number(broker.gst), 2)
      broker.is_edit = true
      this.$refs['agent_broker_comm_gst_' + broker.temp_obj_id][0].setValue(broker.gst)
      this.$refs['agent_broker_comm_total_' + broker.temp_obj_id][0].setValue(broker.total)

      this.update_total_commission()

    },


    comm_gst_watcher(evt, idx, broker) {

      console.log(evt, idx, broker)

      broker.total   = financial(Number(broker.commission) + Number(broker.gst), 2)
      broker.is_edit = true
      this.$refs['agent_broker_comm_total_' + broker.temp_obj_id][0].setValue(broker.total)
      this.update_total_commission()

    },




    update_total_commission() {

      this.deal.listing_commission = 0
      this.deal.listing_commission_gst = 0
      this.deal.listing_commission_total = 0
      this.deal.selling_commission = 0
      this.deal.selling_commission_gst = 0
      this.deal.selling_commission_total = 0


      this.deal.listing_commission = financial(this.deal.listing_brokers.reduce((a, c) => a += Number(c.commission), 0), 2)
      this.deal.listing_commission_gst = financial(this.deal.listing_brokers.reduce((a, c) => a += Number(c.gst), 0), 2)
      this.deal.selling_commission = financial(this.deal.selling_brokers.reduce((a, c) => a += Number(c.commission), 0), 2)
      this.deal.selling_commission_gst = financial(this.deal.selling_brokers.reduce((a, c) => a += Number(c.gst), 0), 2)



      this.deal.selling_commission_total = financial(this.deal.selling_commission + this.deal.selling_commission_gst, 2)
      this.deal.listing_commission_total = financial(this.deal.listing_commission + this.deal.listing_commission_gst, 2)


      this.set_total_commission_value()
    },


    set_total_commission_value() {
      if (this.$refs['listing_commission_ref']) {
        this.$refs['listing_commission_ref'].setValue(this.deal.listing_commission)

        this.$refs['listing_commission_gst_ref'].setValue(this.deal.listing_commission_gst)
        this.$refs['listing_commission_total_ref'].setValue(this.deal.listing_commission_total)
        this.$refs['selling_commission_ref'].setValue(this.deal.selling_commission)
        this.$refs['selling_commission_gst_ref'].setValue(this.deal.selling_commission_gst)
        this.$refs['selling_commission_total_ref'].setValue(this.deal.selling_commission_total)
      }
    },




  },




  data() {
    return {
      title: "Deal",
      items: [
        {
          text: "Deal List",
          href: "/deal/pending_deal",
        },
        {
          text: "Edit Deal",
          active: true,
        },
      ],
      gst_required: false,
      other_broker_name_required: false,




      deal: {
        deal_business: 'R',
        mls: '',
        deal_type: 'L',
        deposit: 0,
        deposit_due_date: '',
        deposit_trust_by: '0',
        order_deal_no: '',
        no_subject: '',
        agents: [],
        properties: [],
        buyers_sellers: [],
        lawyers: [],
        other_brokers: [],
        listing_brokers: [],
        selling_brokers: [],
      },


      deleted_agent: [],

      local_agent_sub_commissions: [],

      searched_property_address: '',
      searched_lawyer: '',
      buery_seller_address: '',
      address_data: [],
      lawyer_data: [],
      lawyer_choices: [],

      local_obj_id: 1000,

      deal_type_choice: {},
      deal_type_list: [
        { label: 'LISTING', value: 'L', selected: false },
        { label: 'SELLING', value: 'S', selected: false },
      ],

      trust_by_choice: {},
      trust_by_list: [
        { label: 'US', value: '1', selected: false },
        { label: 'OTHER', value: '0', selected: false },
      ],



    };
  },
  components: {
    Layout,
    PageHeader,
    flatPickr,
    AgentAutoComplete,
    AddressAutoComplete,
    VueBootstrapTypeahead,
    CurrencyInput,

  },

  created() {



  },

  mounted() {


    this.trust_by_choice = new Choices('#deposit_trust_by', { choices: this.trust_by_list })

    getDealApi().detail({ order_deal_no: this.$route.query.deal_id }).then((res) => {
      if (res.errCode == 0) {
        Object.assign(this.deal, res.data)
        this.deal.order_deal_no = this.deal.order_deal_id


        if (this.deal.deal_business == 'R') {
          this.deal.deal_business_name = 'RESIDENTIAL'
        } else if (this.deal.deal_business == 'C') {
          this.deal.deal_business_name = 'COMMERICIAL'
        } else if (this.deal.deal_business == 'P') {
          this.deal.deal_business_name = 'PROPERTY MANAGEMENT'
        } else if (this.deal.deal_business == 'M') {
          this.deal.deal_business_name = 'Pre Sale'
        } else if (this.deal.deal_business == 'H') {
          this.deal.deal_business_name = 'REFERRAL'
        } else if (this.deal.deal_business == 'T') {
          this.deal.deal_business_name = 'ONE TIME TENANT PLACEMENT'
        }

        if (this.deal.deposits && this.deal.deposits.length > 0) {
          this.deal.deposit = this.deal.deposits[0].deposit
          this.deal.deposit_due_date = this.deal.deposits[0].due_date
          this.deal.deposit_trust_by = this.deal.deposits[0].is_trust_by_us ? this.deal.deposits[0].is_trust_by_us : '0'
        }

        if (!this.deal.buyers_sellers) {
          this.deal.buyers_sellers = []
        }

        if (!this.deal.properties) {
          this.deal.properties = []
        }

        if (!this.deal.listing_brokers) {
          this.deal.listing_brokers = []
        }
      

        if (!this.deal.selling_brokers) {
          this.deal.selling_brokers = []
        }
        this.deal.selling_brokers.map(e => {
          e.agent_name           = e.first_name + ' ' + e.last_name
          e.brokerage            = e.name
          e.temp_obj_id          = ++this.local_obj_id
          e.agent_in_house_type  = 'None In House'
        })
        this.deal.listing_brokers.map(e => {
          e.agent_name           = e.first_name + ' ' + e.last_name
          e.brokerage            = e.name
          e.temp_obj_id          = ++this.local_obj_id
          e.agent_in_house_type  = 'None In House'
        })

        this.deal.buyers_sellers.push(...this.deal.buyers, ...this.deal.sellers)
        this.deal.no_subject = this.deal.is_subject_deal ? false : true

        this.$refs['deal_selling_price'].setValue(this.deal.selling_price)
        this.$refs['deal_deposit'].setValue(this.deal.deposit)



        this.deal_type_list.map(e => e.selected = (e.value == this.deal.deal_type ? true : false))



        this.trust_by_choice.clearChoices()
        this.trust_by_list.map(e => e.selected = (e.value == this.deal.deposit_trust_by ? true : false))

        this.trust_by_choice.setChoices(this.trust_by_list)

        //do exist sub trade
        if (this.deal.agents.length == this.deal.links.length) {
          // bind order agent id and sub deal id to broker
          this.deal.agents.map(a => {
            let broker
             //listing agent
            if (a.agent_type == '1') {
              broker = this.deal.listing_brokers.find(e => e.agent_vin == a.agent_vin)
            //selling agent
            } else if (a.agent_type == '2') {
              broker = this.deal.selling_brokers.find(e => e.agent_vin == a.agent_vin)
            }
      
             
            broker.agent_id       = a.agent_id
            broker.order_agent_id = a.id
            broker.sub_deal       = this.deal.links.find(e => e.bind_agent_id == a.agent_id)
            broker.sub_deal_id    = broker.sub_deal.id
            broker.agent_in_house_type = 'In House'
            broker.agent_type     = a.agent_type
            console.log(broker)
          })

        // we have sub trade
        } else {
          console.log("Exist sub trade")
        }


        this.$nextTick(() => {



          //have to wait refs component set up
          setTimeout(() => {
            let idx = 0
            this.deal.lawyers.map(e => {
              this.$refs['lawyer_name_' + idx][0].inputValue = e.name
              idx++
            })

          }, 1000)

        })

      }

    })




  },

  watch: {

    searched_property_address(new_search_name) {
      this.queryProperty(new_search_name)
    },


    searched_lawyer(new_search_name) {
      this.queryLawyer(new_search_name)
    },



  },

  computed: {

    tab_resident_type() {
      return this.deal.deal_business != 'H' && this.deal.deal_business != 'P' && this.deal.deal_business != 'T'
    },

    tab_referral_type() {
      return this.deal.deal_business == 'H'
    },

    tab_replacement_fee() {
      return this.deal.deal_business == 'P' || this.deal.deal_business == 'T'
    },




  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">

      <div class="col-xxl-12 col-lg-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title mb-4">Edit Deal Info</h5>
            <form class="needs-validation" @submit.prevent="formSubmit">

              <div class="card border shadow-none mb-5">
                <div class="card-header d-flex align-items-center">
                  <div class="flex-shrink-0 me-3">
                  </div>
                  <div class="flex-grow-1">
                    <h5 class="card-title">Deal Type</h5>
                  </div>
                </div>
                <div class="card-body">
                  <div>
                    <div class="row gap-4">
                      <div class="col-lg-2">
                        <div class="mb-3">
                          <label class="form-label">Deal No.</label>
                          <input type="text" :value="deal.order_deal_no" class="form-control" readonly />
                        </div>
                      </div>

                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label">Business Type</label>
                          <input type="text" class="form-control" v-model="deal.deal_business_name" readonly />

                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
              <!-- end card -->

              <!--  tab referral fee /replacement -->
              <div class="row" v-if="tab_referral_type || tab_replacement_fee"
                :class="tab_referral_type || tab_replacement_fee ? '' : 'd-none'">
                <!-- Agent and Commission -->
                <div class="card border shadow-none mb-5">
                  <div class="card-header d-flex align-items-center">
                    <div class="flex-shrink-0 me-3">
                      <div class="avatar-sm">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                          01
                        </div>
                      </div>
                    </div>
                    <div class="flex-grow-1">
                      <h5 class="card-title"> Agent & Commission</h5>
                    </div>
                  </div>
                  <div class="card-body">

                    <div class="row" v-for="(broker, idx) in deal.listing_brokers"
                      :key="'agent_' + idx + '_listing_broker_' + broker.temp_obj_id">

                      <div class="col-lg-2">
                        <div class="mb-3">
                          <label class="form-label">Agent</label>
                          <AgentAutoComplete :ref="'agent_broker_name_' + broker.temp_obj_id" :broker_idx="idx"
                            v-model="broker.agent_name"
                            :init_data="{ user_obj: { broker_index: idx, agent_type: 'LISTING_BROKER', }, agent_name: broker.agent_name, style_class: { 'is-invalid': $v.deal.listing_brokers.$each[idx].agent_name.$error } }"
                            :show_brokerage="false"
                            :search_type="broker.agent_in_house_type == 'In House' ? 'LOCAL' : 'REGBV'"
                            @onSelected="onSelectedBrokerAgent" autocomplete="off" :class="{ 'is-invalid': true }" />
                          <div v-if="$v.deal.listing_brokers.$each[idx].agent_name.$error" class="invalid-feedback">
                            <span v-if="$v.deal.listing_brokers.$each[idx].agent_name.$error">This value is
                              required.</span>
                          </div>

                        </div>
                      </div>
                      <div class="col-lg-2">
                        <div class="mb-3">
                          <label class="form-label">Broker</label>
                          <input type="text" class="form-control" v-model="broker.brokerage" maxlength="80"
                            :readonly="broker.is_local"
                            :class="{ 'is-invalid': $v.deal.listing_brokers.$each[idx].brokerage.$error }" />
                          <div v-if="$v.deal.listing_brokers.$each[idx].brokerage.$error" class="invalid-feedback">
                            <span v-if="$v.deal.listing_brokers.$each[idx].brokerage.$error">This value is
                              required.</span>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-1">
                        <div class="mb-3">
                          <label class="form-label">Commission</label>
                          <CurrencyInput v-model.lazy="broker.commission" :ref="'agent_broker_comm_' + broker.temp_obj_id"
                            @input="comm_watcher($event, idx, broker)" @blur="comm_watcher($event, idx, broker)"
                            :readonly="broker.is_local"
                            :class="{ 'is-invalid': $v.deal.listing_brokers.$each[idx].commission.$error }" />
                          <div v-if="$v.deal.listing_brokers.$each[idx].commission.$error" class="invalid-feedback">
                            <span v-if="$v.deal.listing_brokers.$each[idx].commission.$error">This value is
                              required.</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-1">
                        <div class="mb-3">
                          <label class="form-label">Gst</label>
                          <CurrencyInput v-model.lazy="broker.gst" :ref="'agent_broker_comm_gst_' + broker.temp_obj_id"
                            @input="comm_gst_watcher($event, idx, broker)" @blur="comm_gst_watcher($event, idx, broker)"
                            :readonly="broker.is_local"
                            :class="{ 'is-invalid': $v.deal.listing_brokers.$each[idx].gst.$error }" />
                          <div v-if="$v.deal.listing_brokers.$each[idx].gst.$error" class="invalid-feedback">
                            <span v-if="$v.deal.listing_brokers.$each[idx].gst.$error">This value is required.</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-1">
                        <div class="mb-3">
                          <label class="form-label">Total</label>
                          <CurrencyInput v-model.lazy="broker.total"
                            :ref="'agent_broker_comm_total_' + broker.temp_obj_id" readonly
                            :class="{ 'is-invalid': $v.deal.listing_brokers.$each[idx].gst.$error }" />
                          <div v-if="$v.deal.listing_brokers.$each[idx].gst.$error" class="invalid-feedback">
                            <span v-if="$v.deal.listing_brokers.$each[idx].gst.$error">This value is required.</span>
                          </div>
                        </div>
                      </div>


                    </div>

                  </div>
                </div>
                <!-- end card -->
              </div>
              <!-- End Tab-->

              <!--  tab resident /commercial -->
              <div class="row" v-if="tab_resident_type" :class="tab_resident_type ? '' : 'd-none'">
                <!-- Resident or commercial tab -->
                <div class="card border shadow-none mb-5">
                  <div class="card-header d-flex align-items-center">
                    <div class="flex-shrink-0 me-3">
                      <div class="avatar-sm">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                          01
                        </div>
                      </div>
                    </div>
                    <div class="flex-grow-1">
                      <h5 class="card-title">General Info</h5>
                    </div>
                  </div>
                  <div class="card-body">
                    <div>
                      <div class="row gap-4">

                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label">Price</label>
                            <CurrencyInput v-model.lazy="deal.selling_price"
                              :class="{ 'is-invalid': $v.deal.selling_price.$error }" ref="deal_selling_price" />
                            <div v-if="$v.deal.selling_price.$error" class="invalid-feedback">
                              <span v-if="!$v.deal.selling_price.required">This value is required.</span>
                            </div>

                          </div>
                        </div>

                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label">Deposit</label>
                            <CurrencyInput v-model.lazy="deal.deposit" ref="deal_deposit" />
                            <!-- input type="text" v-model="deal.deposit" class="form-control" placeholder="Enter Amount"
                              /-->
                          </div>
                        </div>

                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label">Deposit Date</label>
                            <flat-pickr v-model="deal.deposit_due_date" placeholder="Select a date" class="form-control"
                              :class="{ 'is-invalid': $v.deal.deposit_due_date.$error }"></flat-pickr>
                            <div v-if="$v.deal.deposit_due_date.$error" class="invalid-feedback">
                              <span v-if="!$v.deal.deposit_due_date.required">This value is required.</span>
                            </div>

                          </div>
                        </div>
                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label">Trust By</label>
                            <select id="deposit_trust_by" v-model="deal.deposit_trust_by" class="form-control">
                            </select>
                          </div>
                        </div>
                      </div>


                      <div class="row  mt-4">
                        <div class="col-sm-2">
                          <div class="mb-3">
                            <label class="form-label">Contract Date</label>
                            <flat-pickr v-model="deal.contract_date" placeholder="Select a date" class="form-control"
                              :class="{ 'is-invalid': $v.deal.contract_date.$error }"></flat-pickr>
                            <div v-if="$v.deal.contract_date.$error" class="invalid-feedback">
                              <span v-if="!$v.deal.contract_date.required">This value is required.</span>
                            </div>

                          </div>
                        </div>
                        <div class="col-sm-2">
                          <div class="mb-3">
                            <label class="form-label" for="gen-info-designation-input">Acceptance Date</label>
                            <flat-pickr v-model="deal.acceptance_date" placeholder="Select a date" class="form-control"
                              :class="{ 'is-invalid': $v.deal.acceptance_date.$error }"></flat-pickr>
                            <div v-if="$v.deal.acceptance_date.$error" class="invalid-feedback">
                              <span v-if="!$v.deal.acceptance_date.required">This value is required.</span>
                            </div>
                          </div>
                        </div>

                        <div class="col-sm-1">
                          <div class="form-check">
                            <label class="form-label">No Subj</label>
                            <input type="checkbox" class="form-check" v-model="deal.no_subject" />
                          </div>
                        </div>

                        <div class="col-sm-2" :class="deal.no_subject ? 'd-none' : 'block'">
                          <div class="mb-3">
                            <label class="form-label" for="gen-info-designation-input">Subject Remove Date</label>
                            <flat-pickr v-model="deal.subject_remove_date" placeholder="Select a date"
                              class="form-control" :disabled="deal.no_subject ? true : false"></flat-pickr>
                          </div>
                        </div>

                        <div class="col-sm-2">
                          <div class="mb-3">
                            <label class="form-label" for="gen-info-designation-input">Recission Date</label>
                            <flat-pickr v-model="deal.recission_date" placeholder="Select a date"
                              class="form-control"></flat-pickr>
                          </div>
                        </div>

                        <div class="col-sm-2">
                          <div class="mb-3">
                            <label class="form-label">Completion Date</label>
                            <flat-pickr v-model="deal.completion_date" placeholder="Select a date" class="form-control"
                              :class="{ 'is-invalid': $v.deal.acceptance_date.$error }"></flat-pickr>
                            <div v-if="$v.deal.acceptance_date.$error" class="invalid-feedback">
                              <span v-if="!$v.deal.acceptance_date.required">This value is required.</span>
                            </div>

                          </div>
                        </div>


                      </div>
                      <!-- end row-->

                    </div>
                  </div>
                </div>
                <!-- end card -->

                <!-- Buyer & Seller  -->
                <div class="card border shadow-none mb-5">
                  <div class="card-header d-flex align-items-center">
                    <div class="flex-shrink-0 me-3">
                      <div class="avatar-sm">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                          02
                        </div>
                      </div>
                    </div>
                    <div class="flex-grow-1">
                      <h5 class="card-title">Buyer & Seller</h5>
                    </div>
                    <div class="flex-grow-1 text-end align-items-right">
                      <b-button variant="primary" @click="onAddNewBuyerSeller" class="col-3">Add Buyer/Seller</b-button>
                    </div>
                  </div>
                  <div class="card-body">

                    <div class="row mt-3" v-for="(bs, idx) in deal.buyers_sellers" :key="'buyer_seller_' + idx">
                      <div class="row">
                        <div class="col-lg-1">
                          <div class="mb-3">
                            <label class="form-label">{{ bs.type }} {{ idx + 1 }}</label>
                            <b-form-select class="form-control buyer_seller_choice" v-model="bs.type">
                              <option value="Buyer">Buyer</option>
                              <option value="Seller">Seller</option>
                            </b-form-select>
                          </div>
                        </div>
                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label">{{ bs.type }} Type</label>
                            <b-form-select class="form-control buyer_seller_choice" v-model="bs.is_coporation">
                              <option value="0">Indivual</option>
                              <option value="1">Corp</option>
                            </b-form-select>
                          </div>
                        </div>
                        <div :class="bs.is_coporation == 1 ? 'col-lg-3' : 'col-lg-2'">
                          <div class="mb-3">
                            <label class="form-label">{{ bs.is_coporation == 1 ? 'CORP Name' : 'First Name' }}</label>
                            <input type="text" class="form-control" v-model="bs.first_name" maxlength="50"
                              :class="{ 'is-invalid': $v.deal.buyers_sellers.$each[idx].first_name.$error }" />
                            <div v-if="$v.deal.buyers_sellers.$each[idx].first_name.$error" class="invalid-feedback">
                              <span v-if="$v.deal.buyers_sellers.$each[idx].first_name.$error">This value is
                                required.</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-2" :class="bs.is_coporation == 1 ? 'd-none' : 'd-block'">
                          <div class="mb-3">
                            <label class="form-label">Last Name</label>
                            <input type="text" class="form-control" v-model="bs.last_name" maxlength="50"
                              :class="{ 'is-invalid': $v.deal.buyers_sellers.$each[idx].last_name.$error }" />
                            <div v-if="$v.deal.buyers_sellers.$each[idx].last_name.$error" class="invalid-feedback">
                              <span v-if="$v.deal.buyers_sellers.$each[idx].last_name.$error">This value is
                                required.</span>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label">Phone</label>
                            <input type="text" class="form-control" v-mask="'(###) ###-####'" v-model="bs.phone"
                              maxlength="30" />
                          </div>
                        </div>

                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label">Email</label>
                            <input class="form-control" v-model="bs.email" maxlength="80" type="email"
                              :class="{ 'is-invalid': $v.deal.buyers_sellers.$each[idx].email.$error, }" />
                            <div v-if="$v.deal.buyers_sellers.$each[idx].email.$error" class="invalid-feedback">
                              <span v-if="$v.deal.buyers_sellers.$each[idx].email.$error">This value should be a valid
                                email.</span>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-1">
                          <div class="row icon-demo-content-10 mb-3 d-flex align-items-start justify-content-md">
                            <label class="form-label"> {{ ' ' }}&nbsp;</label>
                            <div class="col-xl-3 col-lg-4 col-sm-6" @click="onRemoveBuyerSeller(idx)">
                              <i class="uil-times font-size-6"></i>
                            </div>
                          </div>
                        </div>

                      </div>

                      <div class="row">
                        <div class="col-lg-1">
                          <div class="mb-3">
                            <label>Unit</label>
                            <input type="text" class="form-control" v-model="bs.unit" />
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="mb-3">
                            <label class="form-label">Address</label>
                            <AddressAutoComplete :idx="idx" v-model="bs.address" :init_data="{ address: bs.address, }"
                              @onSelected="onBuyerSellerAddressSelected" autocomplete="off" />
                          </div>
                        </div>
                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label>Postal Code</label>
                            <input type="text" class="form-control" v-model="bs.postal_code" />
                          </div>
                        </div>
                        <div class="col-lg-2">
                        </div>

                      </div>
                      <div class="row mb-4">
                        <hr class="my-1" />
                      </div>

                    </div>

                  </div>
                </div>
                <!-- end card -->



                <!-- property address -->
                <div class="card border shadow-none mb-5">
                  <div class="card-header d-flex align-items-center">
                    <div class="flex-shrink-0 me-3">
                      <div class="avatar-sm">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                          03
                        </div>
                      </div>

                    </div>
                    <div class="flex-grow-1">
                      <h5 class="card-title">Property Address</h5>
                    </div>
                    <div class="flex-grow-1 text-end align-items-right">
                      <b-button variant="primary" @click="onAddProperty" class="col-3">Add Address</b-button>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-lg-2">
                        <div class="mb-3">
                          <label>MLS</label>
                          <input type="text" class="form-control" v-model="deal.mls" />
                        </div>
                      </div>
                    </div>

                    <div class="row" v-for="(property, idx) in deal.properties" :key="'property_addr_' + idx">
                      <div class="col-lg-2">
                        <div class="mb-3">
                          <label>PID</label>
                          <input type="text" class="form-control" v-mask="'###-###-###'" v-model="property.pid" />
                        </div>
                      </div>
                      <div class="col-lg-1">
                        <div class="mb-3">
                          <label>Unit</label>
                          <input type="text" class="form-control" v-model="property.unit" />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="mb-3">
                          <label for="workexperience-companyname-input">Property Address</label>
                          <AddressAutoComplete :idx="idx" v-model="property.address" @onSelected="onAddressSelected"
                            autocomplete="off"
                            :init_data="{ address: property.address, style_class: { 'is-invalid': $v.deal.properties.$each[idx].address.$error } }" />
                          <div v-if="$v.deal.properties.$each[idx].address.$error" class="invalid-feedback">
                            <span v-if="$v.deal.properties.$each[idx].address.$error">This value is required.</span>
                          </div>

                        </div>
                      </div>
                      <div class="col-lg-1">
                        <div class="mb-3">
                          <label>Postal Code</label>
                          <input type="text" class="form-control" v-model="property.postal_code" />
                        </div>
                      </div>


                      <div class="col-lg-2">
                        <div class="row icon-demo-content-10 mb-3">
                          <label class="form-label"> {{ ' ' }}&nbsp;</label>
                          <div class="col-xl-3 col-lg-4 col-sm-6" @click="onRemoveProperty(idx)">
                            <i class="uil-times font-size-4"></i>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <!-- end card -->




                <!-- Listing Broker -->
                <div class="card border shadow-none mb-5">
                  <div class="card-header d-flex align-items-center">
                    <div class="flex-shrink-0 me-3">
                      <div class="avatar-sm">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                          04A
                        </div>
                      </div>
                    </div>
                    <div class="flex-grow-1">
                      <h5 class="card-title">Listing Agent & Broker</h5>
                    </div>
                    <div class="flex-grow-1 text-end align-items-right">
                      <b-button variant="primary" @click="onAddListingBroker" class="col-3">Add Entry</b-button>
                    </div>
                  </div>
                  <div class="card-body">

                    <div class="row" v-for="(broker, idx) in deal.listing_brokers"
                      :key="'agent_' + idx + '_listing_broker_' + broker.temp_obj_id">


                      <div class="col-lg-2">
                        <div class="mb-3">
                          <label class="form-label" >Agent Type</label>
                          <b-form-select class="form-control" v-model="broker.agent_in_house_type" :options="[{value : 'In House', text : 'In House'},{value : 'None In House', text : 'None In House'}]" :disabled="broker.id?true:false" ></b-form-select>
                        </div>
                      </div>

                      <div class="col-lg-2">
                        <div class="mb-3">
                          <label class="form-label">Agent</label>
                          <AgentAutoComplete :ref="'agent_broker_name_' + broker.temp_obj_id" :broker_idx="idx"
                            v-model="broker.agent_name" :readonly="broker.is_local"
                            :init_data="{ user_obj: { broker_index: idx, agent_type: 'LISTING_BROKER' }, agent_name: broker.agent_name, style_class: { 'is-invalid': $v.deal.listing_brokers.$each[idx].agent_name.$error } }"
                            :show_brokerage="false" 
                            :search_type="broker.agent_in_house_type == 'In House' ? 'LOCAL' : 'REGBV'"
                            @onSelected="onSelectedBrokerAgent"
                            autocomplete="off" :class="{ 'is-invalid': true }" />
                          <div v-if="$v.deal.listing_brokers.$each[idx].agent_name.$error" class="invalid-feedback">
                            <span v-if="$v.deal.listing_brokers.$each[idx].agent_name.$error">This value is
                              required.</span>
                          </div>

                        </div>
                      </div>
                      <div class="col-lg-2">
                        <div class="mb-3">
                          <label class="form-label">Broker</label>
                          <input type="text" class="form-control" v-model="broker.brokerage" maxlength="80"
                            :readonly="broker.is_local"
                            :class="{ 'is-invalid': $v.deal.listing_brokers.$each[idx].brokerage.$error }" />
                          <div v-if="$v.deal.listing_brokers.$each[idx].brokerage.$error" class="invalid-feedback">
                            <span v-if="$v.deal.listing_brokers.$each[idx].brokerage.$error">This value is
                              required.</span>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-2">
                        <div class="mb-3">
                          <label class="form-label">Commission</label>
                          <CurrencyInput v-model.lazy="broker.commission" :ref="'agent_broker_comm_' + broker.temp_obj_id"
                            @input="comm_watcher($event, idx, broker)" @blur="comm_watcher($event, idx, broker)"
                            :readonly="broker.is_local"
                            :class="{ 'is-invalid': $v.deal.listing_brokers.$each[idx].commission.$error }" />
                          <div v-if="$v.deal.listing_brokers.$each[idx].commission.$error" class="invalid-feedback">
                            <span v-if="$v.deal.listing_brokers.$each[idx].commission.$error">This value is
                              required.</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-1">
                        <div class="mb-3">
                          <label class="form-label">Gst</label>
                          <CurrencyInput v-model.lazy="broker.gst" :ref="'agent_broker_comm_gst_' + broker.temp_obj_id"
                            @input="comm_gst_watcher($event, idx, broker)" @blur="comm_gst_watcher($event, idx, broker)"
                            :readonly="broker.is_local"
                            :class="{ 'is-invalid': $v.deal.listing_brokers.$each[idx].gst.$error }" />
                          <div v-if="$v.deal.listing_brokers.$each[idx].gst.$error" class="invalid-feedback">
                            <span v-if="$v.deal.listing_brokers.$each[idx].gst.$error">This value is required.</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-1">
                        <div class="mb-3">
                          <label class="form-label">Total</label>
                          <CurrencyInput v-model.lazy="broker.total"
                            :ref="'agent_broker_comm_total_' + broker.temp_obj_id" readonly
                            :class="{ 'is-invalid': $v.deal.listing_brokers.$each[idx].gst.$error }" />
                          <div v-if="$v.deal.listing_brokers.$each[idx].gst.$error" class="invalid-feedback">
                            <span v-if="$v.deal.listing_brokers.$each[idx].gst.$error">This value is required.</span>
                          </div>
                        </div>
                      </div>


                      <div class="col-lg-1">
                        <div class="row icon-demo-content-10 mb-3">
                          <label class="form-label"> {{ ' ' }}&nbsp;</label>
                          <div class="col-xl-3 col-lg-4 col-sm-6" @click="onRemoveListingBroker(idx, broker)"
                            v-if="broker.is_local != true">
                            <i class="uil-times font-size-4"></i>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <!-- end card Listing Brokers-->



                <!-- Selling Broker -->
                <div class="card border shadow-none mb-5">
                  <div class="card-header d-flex align-items-center">
                    <div class="flex-shrink-0 me-3">
                      <div class="avatar-sm">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                          04A
                        </div>
                      </div>
                    </div>
                    <div class="flex-grow-1">
                      <h5 class="card-title">Selling Agent & Broker</h5>
                    </div>
                    <div class="flex-grow-1 text-end align-items-right">
                      <b-button variant="primary" @click="onAddSellingBroker" class="col-3">Add Entry</b-button>
                    </div>
                  </div>
                  <div class="card-body">

                    <div class="row" v-for="(broker, idx) in deal.selling_brokers"
                      :key="'agent_' + idx + '_selling_broker_' + broker.temp_obj_id">

                      <div class="col-lg-2">
                        <div class="mb-3">
                          <label class="form-label" >Agent Type</label>
                          <b-form-select class="form-control" v-model="broker.agent_in_house_type" :options="[{value : 'In House', text : 'In House'},{value : 'None In House', text : 'None In House'}]" :disabled="broker.id?true:false"></b-form-select>
                        </div>
                      </div>

                      <div class="col-lg-2">
                        <div class="mb-3">
                          <label class="form-label">Agent</label>
                          <AgentAutoComplete :ref="'agent_broker_name_' + broker.temp_obj_id" :broker_idx="idx"
                            v-model="broker.agent_name" :readonly="broker.is_local"
                            :init_data="{ user_obj: { broker_index: idx, agent_type: 'SELLING_BROKER' }, agent_name: broker.agent_name, style_class: { 'is-invalid': $v.deal.selling_brokers.$each[idx].agent_name.$error } }"
                            :show_brokerage="false" 
                            :search_type="broker.agent_in_house_type == 'In House' ? 'LOCAL' : 'REGBV'"
                            @onSelected="onSelectedBrokerAgent"
                            autocomplete="off" :class="{ 'is-invalid': true }" />
                          <div v-if="$v.deal.selling_brokers.$each[idx].agent_name.$error" class="invalid-feedback">
                            <span v-if="$v.deal.selling_brokers.$each[idx].agent_name.$error">This value is
                              required.</span>
                          </div>

                        </div>
                      </div>
                      <div class="col-lg-2">
                        <div class="mb-3">
                          <label class="form-label">Broker</label>
                          <input type="text" class="form-control" v-model="broker.brokerage" maxlength="80"
                            :readonly="broker.is_local"
                            :class="{ 'is-invalid': $v.deal.selling_brokers.$each[idx].brokerage.$error }" />
                          <div v-if="$v.deal.selling_brokers.$each[idx].brokerage.$error" class="invalid-feedback">
                            <span v-if="$v.deal.selling_brokers.$each[idx].brokerage.$error">This value is
                              required.</span>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-2">
                        <div class="mb-3">
                          <label class="form-label">Commission</label>
                          <CurrencyInput v-model.lazy="broker.commission" :ref="'agent_broker_comm_' + broker.temp_obj_id"
                            @input="comm_watcher($event, idx, broker)" @blur="comm_watcher($event, idx, broker)"
                            :readonly="broker.is_local"
                            :class="{ 'is-invalid': $v.deal.selling_brokers.$each[idx].commission.$error }" />
                          <div v-if="$v.deal.selling_brokers.$each[idx].commission.$error" class="invalid-feedback">
                            <span v-if="$v.deal.selling_brokers.$each[idx].commission.$error">This value is
                              required.</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-1">
                        <div class="mb-3">
                          <label class="form-label">Gst</label>
                          <CurrencyInput v-model.lazy="broker.gst" :ref="'agent_broker_comm_gst_' + broker.temp_obj_id"
                            @input="comm_gst_watcher($event, idx, broker)" @blur="comm_gst_watcher($event, idx, broker)"
                            :readonly="broker.is_local"
                            :class="{ 'is-invalid': $v.deal.selling_brokers.$each[idx].gst.$error }" />
                          <div v-if="$v.deal.selling_brokers.$each[idx].gst.$error" class="invalid-feedback">
                            <span v-if="$v.deal.selling_brokers.$each[idx].gst.$error">This value is required.</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-1">
                        <div class="mb-3">
                          <label class="form-label">Total</label>
                          <CurrencyInput v-model.lazy="broker.total"
                            :ref="'agent_broker_comm_total_' + broker.temp_obj_id" readonly />

                        </div>
                      </div>


                      <div class="col-lg-1">
                        <div class="row icon-demo-content-10 mb-3">
                          <label class="form-label"> {{ ' ' }}&nbsp;</label>
                          <div class="col-xl-3 col-lg-4 col-sm-6" @click="onRemoveSellingBroker(idx, broker)"
                            v-if="broker.is_local != true">
                            <i class="uil-times font-size-4"></i>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <!-- end card Agent & Commission-->



                <!--  Commission Total-->
                <div class="card border shadow-none mb-5">
                  <div class="card-header d-flex align-items-center">
                    <div class="flex-shrink-0 me-3">
                      <div class="avatar-sm">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                          04C
                        </div>
                      </div>
                    </div>
                    <div class="flex-grow-1">
                      <h5 class="card-title">Commission</h5>
                    </div>
                  </div>
                  <div class="card-body">
                    <div>
                      <div class="row gap-4">

                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label">Listing Commission</label>
                            <CurrencyInput ref="listing_commission_ref" readonly />
                          </div>
                        </div>

                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label">Listing Commission Gst</label>
                            <CurrencyInput ref="listing_commission_gst_ref" readonly />
                          </div>
                        </div>

                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label">Total Listing Commission</label>
                            <CurrencyInput ref="listing_commission_total_ref" readonly />
                          </div>
                        </div>

                      </div>
                      <div class="row gap-4">
                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label">Selling Commisison</label>
                            <CurrencyInput ref="selling_commission_ref" readonly />

                          </div>

                        </div>
                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label">Selling Commisison Gst</label>
                            <CurrencyInput ref="selling_commission_gst_ref" readonly />

                          </div>

                        </div>
                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label">Total Selling Commisison</label>
                            <CurrencyInput ref="selling_commission_total_ref" readonly />
                          </div>

                        </div>



                      </div>
                    </div>
                  </div>
                </div>
                <!-- end card Commission -->



                <!-- Lawyer  -->
                <div class="card border shadow-none mb-5">
                  <div class="card-header d-flex align-items-center">
                    <div class="flex-shrink-0 me-3">
                      <div class="avatar-sm">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                          05
                        </div>
                      </div>
                    </div>
                    <div class="flex-grow-1">
                      <h5 class="card-title">Lawyer</h5>
                    </div>
                    <div class="flex-grow-1 text-end align-items-right">
                      <div class="flex-grow-1 text-end align-items-right">
                        <b-button variant="primary" @click="onAddNewLawyer" class="col-3">Add Lawyer</b-button>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">

                    <div class="row" v-for="(lawyer, idx) in deal.lawyers" :key="'lawyer_' + idx + '_' + lawyer.id">
                      <div class="row">
                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label">Lawyer {{ idx + 1 }}</label>
                            <select class="form-control lawyer_choice" v-model="lawyer.type">
                              <option value="Buyer Lawyer" selected>Buyer Lawyer</option>
                              <option value="Seller Lawyer">Seller Lawyer</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-lg-3">
                          <div class="mb-3">
                            <label class="form-label">Name</label>
                            <vue-bootstrap-typeahead v-model="searched_lawyer" :value="lawyer.name" :remoteMatch="true"
                              :data="lawyer_data" :serializer="s => s.name" :foramterDisplay="s => s.name"
                              :ref="'lawyer_name_' + idx" @hit="onLawyerSelected($event, lawyer, idx)"
                              autocomplete="off" />


                          </div>
                        </div>
                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label">Phone</label>
                            <input type="text" class="form-control" v-mask="'(###) ###-####'" v-model="lawyer.phone" />
                          </div>
                        </div>

                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label">Email</label>
                            <input type="text" class="form-control" v-model="lawyer.email" />
                          </div>
                        </div>

                        <div class="col-lg-2">
                          <div class="mb-3">
                            <label class="form-label">Email2</label>
                            <input type="text" class="form-control" v-model="lawyer.email2" />
                          </div>
                        </div>
                        <div class="col-lg-1">

                          <div class="row icon-demo-content-10 mb-3">
                            <label class="form-label"> {{ ' ' }}&nbsp;</label>
                            <div class="col-xl-3 col-lg-4 col-sm-6" @click="onRemoveLawyer(idx)">
                              <i class="uil-times font-size-4"></i>
                            </div>
                          </div>
                        </div>


                      </div>
                      <div class="row">
                        <div class="col-lg-1">
                          <div class="mb-3">
                            <label>Unit</label>
                            <input type="text" class="form-control" v-model="lawyer.unit" />
                          </div>
                        </div>
                        <div class="col-lg-10">
                          <div class="mb-3">
                            <label class="form-label">Address</label>
                            <AddressAutoComplete :lawyer_index="idx" v-model="searched_property_address"
                              :ref="'lawyer_address_' + idx" @onSelected="onLawyerAddreessSelected" autocomplete="off"
                              :init_data="{ address: lawyer.address }" />
                          </div>
                        </div>
                      </div>
                      <div class="row mb-4">
                        <hr class="my-1" />
                      </div>

                    </div>



                  </div>
                </div>
                <!-- end card -->

              </div>

              <div class="row">
                <div class="col-md-12 d-flex align-items-right justify-content-sm-end">
                  <div class="text-end d-flex flex-wrap gap-2">
                    <button type="button" class="btn btn-secondary w-sm"
                      @click="$router.push({ name: 'deal-overview', query: { deal_id: deal.order_deal_id } })"
                      @keyup.enter.prevent=""> Cancel </button>
                    <button type="submit" class="btn btn-primary w-sm" @keyup.enter.prevent=""> Submit Deal </button>
                  </div>
                </div>
              </div>
            </form>
            <!-- end form -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
